


















































import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ProgressPayment from '../../components/ProgressPayment/index.vue';
import BoxPaymentPrice from '../../components/BoxPaymentPrice/BoxPaymentPrice.vue';
import PixCode from '../../components/PixCode/PixCode.vue';
import BoxPaymentInstruction from '../../components/BoxPaymentInstruction/BoxPaymentInstruction.vue';

import PaymentPixLoading from '../../components/PaymentPixBilletLoading/index.vue';

import { INSTRUCTIONS_PIX } from '../../constants/instructions';
import { METHOD_BY_BILLET, METHOD_BY_PIX, PAYMENT_STATUS } from '../../constants';

import PaymentService from '@/services/Payment/PaymentService';

import { PaymentSuccess } from '../../interfaces/IPayment';

@Component({
  components: {
    PageHeader,
    Footer,
    ProgressPayment,
    BoxPaymentPrice,
    PixCode,
    BoxPaymentInstruction,
    PaymentPixLoading,
    FeedbackUser
  }
})
export default class PaymentPix extends Vue {
  private INSTRUCTIONS_PIX = INSTRUCTIONS_PIX;
  private isLoading = false;
  private paymentData: PaymentSuccess | null = null;

  private PaymentService = new PaymentService();

  async created() {
    await this.getPayment();
    this.validatePaymentMethod();
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  async getPayment() {
    try {
      this.isLoading = true;
      const { idPurchase } = this.$route.params;

      const [payment]: PaymentSuccess[] = await this.PaymentService.getPayment({
        statusExternal: PAYMENT_STATUS.PENDING,
        paymentID: Number(idPurchase)
      });

      if (payment) {
        this.paymentData = payment;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao retornar os dados do pagamento.',
        status: 'error'
      });
      console.error({ error });
    } finally {
      this.isLoading = false;
    }
  }

  validatePaymentMethod() {
    if (!this.paymentData || this.paymentData.payment.method === METHOD_BY_PIX) return;

    if (this.paymentData.payment.method === METHOD_BY_BILLET) {
      this.redirectToPaymentByBillet();
    }
  }

  redirectToPaymentByBillet() {
    const { feature, productId, idPurchase } = this.$route.params;

    this.$router.push({
      name: 'PaymentBillet',
      params: {
        feature,
        productId,
        idPurchase
      }
    });
  }

  redirectToPending() {
    this.$router.push({
      name: 'Pendencies'
    });
  }

  redirectToPaymentThankYou() {
    const { feature, productId, idPurchase } = this.$route.params;

    this.$router.push({
      name: 'PaymentThankYou',
      params: {
        feature,
        productId,
        idPurchase
      }
    });
  }

  redirectToStore() {
    this.$router.push({ name: 'Store' });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'loja', to: '/loja' },
      { title: 'Pagamento', to: null },
      { title: 'Pix', to: null }
    ]);
  }
}
